import store from '@/store/bryce'
import apiClient from '@/services/shared/AxiosService.js'

const portalMethods = {
  addLicenseOrder(options) {
    let apiUrl = 'transactional/add_license_order/'
    let disabled_at = null
    if (options['order'].monitoring) apiUrl = 'monitoring/add_license/'
    if (options['order'].info_only)  disabled_at = new Date()
    return apiClient({
      method: 'post',
      url: apiUrl,
      data: {
        license_reference: options['order'].license_reference,
        license_number: options['order'].license_number,
        license_state: options['order'].license_state,
        // license_multistate: options['order'].license_multistate,
        license_type: options['order'].license_type,
        subject_id: options['order'].subject_id,
        disabled_at: disabled_at
      }
    })
  },
  addSearchOrder(options) {
    let apiUrl = 'transactional/add_search_order/'
    if (options.monitoring) apiUrl = 'monitoring/add_search/'
    return apiClient({
      method: 'post',
      url: apiUrl,
      data: {
        tier: options.tier,
        verify: options.verify,
        subject_id: options.subjectId,
        add_ons: options.addOns
      }
    })
  },
  addSubject(options) {
    let apiUrl = 'transactional/add_subject/'
    let yearOfBirth = null
    let monthOfBirth = null
    let dayOfBirth = null
    if (options['subject'].birthDate) {
      let date = options['subject'].birthDate.substring(0, 10).split('-')
      monthOfBirth = +date[0]
      dayOfBirth = +date[1]
      yearOfBirth = +date[2]
    }
    let data = {
      reference: options['subject'].reference,
      organization_name: options['subject'].organizationName,
      batch_action_type: options['subject'].batchActionType,
      batch_name: options['subject'].batchName,
      batch_file: options['subject'].batchFile,
      batch_group: options['subject'].batchGroup,
      first_name: options['subject'].firstName,
      middle_name: options['subject'].middleName,
      last_name: options['subject'].lastName,
      gender: options['subject'].gender,
      year_of_birth: yearOfBirth,
      month_of_birth: monthOfBirth,
      day_of_birth: dayOfBirth,
      ssn: options['subject'].socialSecurityNumber,
      npi: options['subject'].npi,
      dea: options['subject'].dea,
      address_street: options['subject'].addressStreet,
      address_city: options['subject'].addressCity,
      address_state: options['subject'].addressState,
      address_zip: options['subject'].addressZip,
      license_number: options['subject'].licenseNumber,
      subject_type: options['subject'].subjectType,
      provider_type_id: parseInt(options['subject'].providerType)
    }
    if (options['monitoring']) {
      apiUrl = 'monitoring/enroll_subject/'
      data['group'] = options['subject'].group
    }
    return apiClient({
      method: 'post',
      url: apiUrl,
      data: data
    })
  },
  updateSubject(options) {
    let apiUrl = 'monitoring/update_subject/'
    let yearOfBirth = null
    let monthOfBirth = null
    let dayOfBirth = null
    if (options['subject'].birthDate) {
      let date = options['subject'].birthDate.substring(0, 10).split('-')
      monthOfBirth = +date[0]
      dayOfBirth = +date[1]
      yearOfBirth = +date[2]
    }
    let data = {
      id: options['subject'].id,
      reference: options['subject'].reference,
      organization_name: options['subject'].organizationName,
      first_name: options['subject'].firstName,
      middle_name: options['subject'].middleName,
      last_name: options['subject'].lastName,
      gender: options['subject'].gender,
      year_of_birth: yearOfBirth,
      month_of_birth: monthOfBirth,
      day_of_birth: dayOfBirth,
      npi: options['subject'].npi,
      dea: options['subject'].dea,
      address_street: options['subject'].addressStreet,
      address_city: options['subject'].addressCity,
      address_state: options['subject'].addressState,
      address_zip: options['subject'].addressZip,
      group: options['subject'].group
    }
    let ssn = options.subject.socialSecurityNumber
    if ('00000' != ssn.slice(0, 5) && '0000' != ssn.slice(-4)) {
      data['ssn'] = ssn
    }
    return apiClient({
      method: 'post',
      url: apiUrl,
      data: data
    })
  },
  resultMarkAsRead(options) {
    if (options.isMonitoring) return apiClient({ method: 'post', url: 'monitoring/acknowledge_alert/', data: options })
    else if (options.license_result_id) return apiClient({ method: 'post', url: 'transactional/acknowledge_license_result/', data: options })
    else return apiClient({ method: 'post', url: 'transactional/acknowledge_search_result/', data: options })
  },
  resultMarkAsUnRead(options) {
    if (options.isMonitoring) return apiClient({ method: 'post', url: 'monitoring/unacknowledge_alert/', data: options })
    else if (options.license_result_id) return apiClient({ method: 'post', url: 'transactional/unacknowledge_license_result/', data: options })
    else return apiClient({ method: 'post', url: 'transactional/unacknowledge_search_result/', data: options })
  },
  alertUpdate(options) {
    return apiClient({ method: 'post', url: 'monitoring/update_alert/', data: options })
  },
  searchResultUpdate(options) {
    return apiClient({ method: 'post', url: 'transactional/update_search_result/', data: options })
  },
  licenseResultUpdate(options) {
    return apiClient({ method: 'post', url: 'transactional/update_license_result/', data: options })
  },
  getMonitoringAlerts(options) {
    return apiClient({
      method: 'post',
      url: 'monitoring/get_alerts/',
      data: options
    })
  },
  getMonitoringRoster(options) {
    return apiClient({
      method: 'post',
      url: 'monitoring/search_monitor_subject_history/',
      data: options
    })
  },
  getOnetimeRoster(options) {
    return apiClient({
      method: 'post',
      url: 'transactional/search_subject_history/',
      data: options
    })
  },
  getAlertCount(options) {
    return apiClient({ method: 'post', url: 'monitoring/get_alerts_count/', data: options })
  },
  getAlertInfo(options) {
    return apiClient({ method: 'post', url: 'monitoring/get_alert_detail/', data: options })
  },
  // options["batchId"], options["monitoring"]
  getBatchFile(options) {
    let apiUrl = 'transactional/get_batch_file/'
    if (options['monitoring']) apiUrl = 'monitoring/get_batch_file/'
    return apiClient({ method: 'post', url: apiUrl, data: options })
  },
  getCustomer(options) {
    return apiClient({ method: 'post', url: 'reseller/get_customer/', data: options })
  },
  getCustomers(options) {
    return apiClient({ method: 'post', url: 'reseller/search_customers/', data: options })
  },
  getGroups() {
    return apiClient({ method: 'post', url: 'monitoring/get_groups/', data: {} })
  },
  getMonitoringSubjectEnrollHistory(options) {
    return apiClient({ method: 'post', url: 'monitoring/get_subject_enroll_history/', data: options })
  },
  getMonitoringProductEnrollHistory(options) {
    return apiClient({ method: 'post', url: 'monitoring/get_product_enroll_history/', data: options })
  },
  getProducts() {
    return apiClient({ method: 'post', url: 'user/get_products/', data: {} })
  },
  getProductsCustomer(options) {
    return apiClient({ method: 'post', url: 'reseller/get_products/', data: { customer_id: options.customerId } })
  },
  getUserProfile() {
    return apiClient({ method: 'post', url: 'user/get_user_profile/', data: {} })
  },
  updateUserProfile(options) {
    return apiClient({ method: 'post', url: 'user/update_user_profile/', data: options })
  },
  getSubjectInfo(options) {
    let url = 'transactional/get_subject/'
    if (options['monitoring']) url = 'monitoring/get_subject_info/'
    return apiClient({ method: 'post', url: url, data: { subject_id: +options['subjectId'] } })
  },
  getTransactionalLicenseResult(options) {
    return apiClient({ method: 'post', url: 'transactional/get_license_result/', data: options })
  },
  getTransactionalLicenseResults(options) {
    return apiClient({ method: 'post', url: 'transactional/get_license_results/', data: options })
  },
  getTransactionalAdverseAction(options) {
    return apiClient({ method: 'post', url: 'transactional/get_search_result/', data: options })
  },
  getTransactionalAdverseActions(options) {
    return apiClient({ method: 'post', url: 'transactional/get_search_results/', data: options })
  },
  getTransactionalSubject(options) {
    return apiClient({ method: 'post', url: 'transactional/get_subject/', data: options })
  },
  getTransactionalSubjectOrders(options) {
    return apiClient({ method: 'post', url: 'transactional/get_subject_orders/', data: options })
  },
  getTransactionalSearchHistory(options) {
    return apiClient({ method: 'post', url: 'transactional/search_subject_history/', data: options })
  },
  getBatches(options) {
    if (options['monitoring']) return apiClient({ method: 'post', url: 'monitoring/get_batches/', data: options['searchOptions'] })
    else return apiClient({ method: 'post', url: 'transactional/get_batches/', data: options['searchOptions'] })
  },
  getBatchInfo(options) {
    if (options['monitoring']) return apiClient({ method: 'post', url: 'monitoring/get_batch_info/', data: options['searchOptions'] })
    else return apiClient({ method: 'post', url: 'transactional/get_batch_info/', data: options['searchOptions'] })
  },
  getMonitoringLicenseResult(options) {
    return apiClient({
      method: 'post',
      url: 'monitoring/get_license_results/',
      data: {
        subject_id: +options['subjectId'],
        monitoring_product_id: +options['monitoringProductId']
      }
    })
  },
  getMonitorProductOrders(options) {
    return apiClient({
      method: 'post',
      url: 'monitoring/get_subject_products/',
      data: { subject_id: +options['subjectId'] }
    })
  },
  getOrderLogs() {
    return apiClient({ method: 'post', url: 'transactional/get_product_order_logs/' })
  },
  getUserLogs() {
    return apiClient({ method: 'post', url: 'user/get_user_logs/' })
  },
  removeLicenseOrder(options) {
    return apiClient({ method: 'post', url: 'monitoring/remove_product/', data: { subject_id: +options['subjectId'], monitoring_product_id: +options['monitoringProductId'] } })
  },
  stageBatchFile(options) {
    return apiClient({
      method: 'post',
      url: 'transactional/stage_batch_file/',
      data: options['fd'],
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        store.state.batch.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      }
    })
  },
  resumeSubject(options) {
    return apiClient({ method: 'post', url: 'monitoring/resume_subject/', data: { subject_id: +options['subjectId'] } })
  },
  pauseSubject(options) {
    return apiClient({ method: 'post', url: 'monitoring/unenroll_subject/', data: { subject_id: +options['subjectId'] } })
  },
  updateProductEnabled(options) {
    return apiClient({ method: 'post', url: 'reseller/toggle_product/', data: options })
  },
  uploadBatch(options) {
    let url = options['monitoring'] ? 'monitoring/upload_batch/' : 'transactional/upload_batch/'
    return apiClient({
      method: 'post',
      url: url,
      data: {
        batch_name: options['batchProduct'].batchName,
        staged_file_id: options['batchProduct'].fileId,
        is_individual: options['batchProduct'].isIndividual,
        include_license_check: options['batchProduct'].includeLicenseCheck,
        include_license_search: options['batchProduct'].includeLicenseSearch,
        include_license_info: options['batchProduct'].includeLicenseInfo,
        tier: options['batchProduct'].tier,
        addOns: options['batchProduct'].addOnProductTiers,
        verify: options['batchProduct'].verify,
        group: options['batchProduct'].group,
        action_type: options['batchProduct'].batchActionType,
        finalize: options['batchProduct'].finalize
      }
    })
  },
  validateBatch(options) {
    let url = options['monitoring'] ? 'monitoring/validate_batch/' : 'transactional/validate_batch/'
    return apiClient({
      method: 'post',
      url: url,
      data: {
        batch_name: options['batchProduct'].batchName,
        staged_file_id: options['batchProduct'].fileId,
        is_individual: options['batchProduct'].isIndividual,
        include_license_check: options['batchProduct'].includeLicenseCheck,
        include_license_search: options['batchProduct'].includeLicenseSearch,
        include_license_info: options['batchProduct'].includeLicenseInfo,
        tier: options['batchProduct'].tier,
        verify: options['batchProduct'].verify,
        group: options['batchProduct'].group,
        action_type: options['batchProduct'].batchActionType,
        finalize: options['batchProduct'].finalize
      }
    })
  }
}

export default {
  send(options) {
    console.log(options['method'])
    console.log(options['params'])
    return portalMethods[options['method']](options['params'])
      .then(response => {
        console.log(response)
        if (response.data.status == 'OK') {
          return response.data
        } else if (response.data.status == 'INVALID_TOKEN') {
          console.log('Bryce Token expired...logging out.')
          store.dispatch('auth_user/authExpire', {}, { root: true })
          return null
        } else {
          return response.data
        }
      })
      .catch(error => {
        const notification = { type: 'error', message: 'There was an error ' + options['message'] + ': ' + error.message + '. Calling: ' + options['method'] }
        store.dispatch('notification/add', notification, { root: true })
      })
  }
}
